











































































































import { Component } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import { Form } from 'element-ui'
import BindPop from '@/components/BindPop.vue'
import VerifyCode from '@/components/VerifyCode.vue'

@Component({ name: 'ChangePassword', components: { BindPop, VerifyCode, } })
export default class ChangePassword extends VueBase {
  submitForm = {
    old_password: '',
    new_password: '',
    checkPass: '',
  }

  private rules = {
    old_password: [{ validator: this.validateOldPass, trigger: 'blur' }],
    new_password: [{ validator: this.validateNewPass, trigger: 'blur' }],
    checkPass: [{ validator: this.validateCheckPass, trigger: 'blur' }],
  }

  private ladpForm = {
    username: '',
    password: '',
  }

  $store: any
  get userInfo() {
    return this.$store.getters.userInfo
  }

  private async checkLdap(){
    this.loadingStart()
    const { status, msg } = await this.services.user.bindLdap(this.ladpForm)
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.$message({
      type: 'success',
      message: '验证成功',
      showClose: true,
    })
  }

  private validateOldPass(rule: any, value: any, callback: any) {
    if (value === '') {
      callback(new Error(this.$t('base.oldPassword') as string))
    } else {
      if (this.submitForm.old_password !== '') {
        ;(this.$refs.ruleForm as Form).validateField('new_password')
      }
      callback()
    }
  }

  private validateNewPass(rule: any, value: string, callback: any) {
    if (value === '') {
      callback(new Error(this.$t('base.newPassword') as string))
    } else {
      if (this.submitForm.new_password !== '') {
        ;(this.$refs.ruleForm as Form).validateField('checkPass')
      }
      callback()
    }
  }

  private validateCheckPass(rule: any, value: any, callback: any) {
    if (value === '') {
      callback(new Error(this.$t('base.rePassword') as string))
    } else if (value !== this.submitForm.new_password) {
      callback(new Error(this.$t('base.diffPassword') as string))
    } else {
      callback()
    }
  }

  private changePassword() {
    ;(this.$refs.ruleFormSettingCode as Form).validate(async (valid: any) => {
      if (valid) {
        const params = {
          username: this.userInfo.username,
          old_password: this.submitForm.old_password,
          new_password: this.submitForm.new_password,
        }
        this.loadingStart()
        const { status, msg } = await this.services.user.changePassword(params)
        this.loadingDone()
        if (status !== 201) {
          this.$message({
            type: 'error',
            message: msg,
            showClose: true,
          })
          return
        }
        await this.$store.dispatch('user/clearInfo')
        await this.$router.push('/login')
      } else {
        console.log('error submit!!')
        return false
      }
    })
  }

  private showBindPop: boolean = false
  private vCode: string = ''
  private uri: any = ''
  async submitCode() {
    if (this.vCode.length !== 6) {
      this.$message.warning('请填写六位验证码')
      return
    }
    const { data, status } = await this.services.user.getCode({
      totp_code: this.vCode
    })
    if (status !== 201) {
      this.$message.error('验证失败，请再次尝试')
      return
    }
    this.uri = data
    this.showBindPop = true
  }
}
